.printable_invoice {
  display: none;
  margin: 5rem;
}
@media print {
  .printable_invoice {
   display: unset;
  }
  @page {
     /* size: landscape;  */
  }
}