.ant-select-selection-search {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #5e6278 !important;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.ant-select-selector {
  background: #f5f8fa !important;
}
.ant-select-selection-item-content{
  color:blueviolet

}
